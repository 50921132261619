
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { setSelectedDivision } from 'store/functions/state';
import { getSoftRefreshLink } from 'utils/urls';

import Circle from 'components/markup/loading/Circle';

import Header from './Header';
import Navbar from './Navbar';
import Results from './Results';
import Sidebar from './Sidebar';

import api from 'api';
import { toast } from 'react-toastify'

import { getRangeByValue } from './RangeSelection';

const AnalyticsPagesMatters = ({selected_division, columns, query, sidebar, onSortChange, initialState, title, type}) => {

    const [isDownloading, setIsDownloading] = useState('');
    const [redirect, setRedirect] = useState('');
    const [fields, setFields] = useState(null);

    const [data, setData] = useState(null)
    const [limit, setLimit] = useState(15)
    
    const [sort, setSort] = useState({
        type: 'created_at',
        field: ''
    });
    
    const [range, setRange] = useState(getRangeByValue());
    const [filter, setFilter] = useState(initialState)

    const onSetSort = useCallback((field, value) => {
        const _sort = JSON.parse(JSON.stringify(sort))
        _sort[field] = value;
        if(field === 'type') _sort.field = ''
        setSort(_sort)
        if(onSortChange) onSortChange(_sort)
    }, [sort, onSortChange])
    

    const onSetFilter = useCallback((field, value, isCustomField) => {
        const _filter = JSON.parse(JSON.stringify(filter))
        if(isCustomField) {
            _filter.custom_fields[field] = value
        } else {
            _filter[field] = value
        }
        setFilter(_filter)
    }, [filter])
    
    const onRangeChange = useCallback(({start, end}) => {
        const _range = JSON.parse(JSON.stringify(range))
        _range.start = start
        _range.end = end
        setRange(_range)
    }, [range])

    const onAddArrayItem = useCallback((type, obj) => {
        const _filter = JSON.parse(JSON.stringify(filter))
        const array = _filter[type] ? _filter[type] : [];
        const includes = array.some(a => a.value === obj.value);
        if(!includes) {
            array.push(obj);
            _filter[type] = array;
            setFilter(_filter)
        }
    }, [filter])

    const onRemoveArrayItem = useCallback((type, obj) => {
        const _filter = JSON.parse(JSON.stringify(filter))
        let array = _filter[type] ? _filter[type] : [];
        array = array.filter(a => a.value !== obj.value)
        _filter[type] = array;
        setFilter(_filter)
    }, [filter])

    const onSetDivision = useCallback((division) => {
        setRedirect(getSoftRefreshLink())
        setSelectedDivision(division._id)
    }, [])

    const fetchFields = useCallback(async () => {
        const data = await api.analyze.fields(selected_division._id)
        setFields(data.data)
    }, [selected_division._id])

    const runQuery = async (params, isCSV) => new Promise (async resolve => {
        let _filter = JSON.parse(JSON.stringify(filter));

        Object.keys(filter).forEach(k => {
            if(Array.isArray(_filter[k])) _filter[k] = _filter[k].map(o => o.value)
        })
        if(isCSV) {
            setIsDownloading(true);
            toast.info(`Your download has started.`)
        }
        const data = await query({
            filter: _filter, 
            sort, 
            range, 
            limit: params.limit ? params.limit : limit, 
            skip: params.skip || 0,
            isCSV
        })
        if(isCSV) {
            setIsDownloading(false);
            toast.success(`Your download has finished.`)
        }

        if(data.success) {
            if(isCSV) {
                var hiddenElement = document.createElement('a');
                hiddenElement.href = 'data:text/csv;charset=utf-8,' + data.data;
                hiddenElement.target = '_blank';
                hiddenElement.download = `matter_export.csv`;
                hiddenElement.click();                
            } else {
                setData(data.data)
            }
        }
        return resolve(data.data)
    })

    useEffect(() => {
        fetchFields()
    }, [fetchFields])

    if(redirect) return <Redirect to={redirect} />
    if(!fields) return <Circle className="py-6" />

    return (
        <div className="archk-query ">

            <Sidebar
                fields={fields}
                filter={filter}
                onSetFilter={onSetFilter}
                onAddArrayItem={onAddArrayItem}
                onRemoveArrayItem={onRemoveArrayItem}
                sidebar={sidebar}
            />

            <div className="archk-query-main">
                
                <Navbar 
                    onSetDivision={onSetDivision}
                    title={title}
                />
                
                <Header 
                    sort={sort}
                    onSetSort={onSetSort}
                    fields={fields}
                    onRangeChange={onRangeChange}
                    runQuery={runQuery}
                    type={type}
                />

                <Results 
                    sort={sort}
                    data={data}
                    limit={limit}
                    setLimit={setLimit}
                    runQuery={runQuery}
                    columns={columns}
                    isDownloading={isDownloading}
                />


            </div>

        </div>
    )
}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(AnalyticsPagesMatters);