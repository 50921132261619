/*

Example Usage

 <PieArea 
    type="pie"
    title="New Leads"
    labels={['Red', 'Blue', 'Yellow', 'Green', 'Indigo', 'Purple', 'Orange', 'purple']}
    dataset={[12, 19, 3, 5, 2, 3, 5, 3]}
/>

*/


import { Pie, PolarArea } from "react-chartjs-2";
import { formatNumber } from '../_functions/utils';
import { useState, useEffect } from 'react'

import settings from '../settings'

const ChartBar = ({title, type, labels, dataset}) => {

    const [data, setData] = useState(null)

    useEffect(() => {
        let backgroundColor = []
        let borderColor = []

        Object.keys(settings.colors).forEach(key => {
            backgroundColor.push(settings.colors[key] + '0.7)')
            borderColor.push(settings.colors[key] + '1)')
        })

        setData({
            labels: labels,
            datasets: [
                {
                  label: '# of Votes',
                  data: dataset,
                  backgroundColor: dataset.color ? dataset.color : backgroundColor,
                  borderColor: dataset.color ? dataset.color : borderColor,
                  borderWidth: 1,
                },
              ],
          })
    }, [type, labels, dataset])

    if(!data) return null

    const Component = type === 'area' ? PolarArea : Pie

    return (
        <div className="border z-depth-1 mb-4">
            
            <div className="p-4 border-bottom">
                <h2 className=" mb-0">{title}</h2>
            </div>

            <div className="p-4">

                <div className="chart">
                    <Component
                        data={data}
                        options={{
                            maintainAspectRatio: false,
                            tooltips: {
                                callbacks: {
                                    label: function(tooltipItem, data) {

                                        var dataset = data.datasets[tooltipItem.datasetIndex];
                                        var currentValue = dataset.data[tooltipItem.index];

                                        let defaultLabel = dataset.label + ' ' + formatNumber(currentValue, false, 0)

                                        if(data.datasets.length >= 3 && tooltipItem.datasetIndex !== 0) {

                                            let total = 0
                                            data.datasets.forEach((d, i) => total += i === 0 ? 0 : d.data[tooltipItem.index])

                                            var percentage = parseFloat((currentValue/total*100).toFixed(1));
                                            return defaultLabel + ' (' + percentage + '%)';

                                        }

                                        return defaultLabel + '%'

                                    },
                                    title: function(tooltipItem, data) {
                                        return data.labels[tooltipItem[0].index];
                                    }
                                }
                            }
                        }}

                        className="chart-canvas"
                    />
                </div>
            </div>
        </div>

    )
}

export default ChartBar;
