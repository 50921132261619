

import { connect } from 'react-redux';
import { Card, CardFooter } from "reactstrap";

import Table from 'components/functional/tables/Standard';

const AnalyticsPagesMattersResults = ({viewing_user, limit, data, setLimit, runQuery, isDownloading, columns}) => {

    return (
        <Card className=" archk-query-results card-color card-primary table-fixe table-no-stripes z-depth-0 mb-0    ">
            <Table

                query={runQuery}
                hideSearch={true}
                rowClasses={(row) => {
                    if(!row.roles) return ''
                    const isAssignedOnRole = row.roles.some(r => r.user === viewing_user._id)
                    return isAssignedOnRole ? 'row-mine' : '';
                }}
                columns={columns}
                data={data && data.documents && data.documents.length ? data.documents : [{_id: 'test', name: 'no data'}]}
                totalDocuments={data ? data.total_documents : 0}
                onSizePerPageChange={(value) => setLimit(parseInt(value))}
                sizePerPage={limit}
            />
            
            <CardFooter className='border-top z-depth-1'>
            <p className='text-sm text-right mb-0'>
                {isDownloading ? (
                    <span className='text-muted' onClick={() => runQuery({}, true)}><i className="fas fa-download text-info" /> Export ResultsTo CSV</span>
                ) : (
                    <span className='cursor-pointer' onClick={() => runQuery({}, true)}><i className="fas fa-download text-info" /> Export ResultsTo CSV</span>
                )}
            </p>
            </CardFooter>

        </Card>
        
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
	    DOMAINS: state.load.DOMAINS,

	};
};

export default connect(mapStateToProps, '')(AnalyticsPagesMattersResults);
