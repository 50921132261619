import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Row, Col } from "reactstrap";

import api from 'api';

import Modalify from 'components/functional/modals/Modalify';

import PhoneInput from 'react-phone-input-2';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

import SearchCollections from 'components/system/Search/Collections';
import ReactSelect from 'components/functional/inputs/ReactSelect';

import moment from 'moment'

import { toast } from 'react-toastify'

const baseState = {
    lead_source       : null,
    call_flow         : null,
    number            : '+1',
    name              : '',
    record_inbound    : false,
    record_outbound   : false,
}



const CallPhoneNumberEdit = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [callPhoneNumber, setCallPhoneNumber] = useState(null)
    const [canSync, setCanSync] = useState(true)
    const [err, setErr] = useState(null)


    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setCallPhoneNumber({ ...baseState });
        const user = await api.call_phone_numbers.findById(_id)
        if(!user.data) return setErr(true);
        setCallPhoneNumber(user.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newCallPhoneNumber = JSON.parse(JSON.stringify(callPhoneNumber))
        newCallPhoneNumber[field] = value;
        setCallPhoneNumber(newCallPhoneNumber);
    }, [callPhoneNumber])

    const onSync = useCallback(async () => {
        if(!canSync) return;
        setCanSync(false)
        const updated = await api.call_phone_numbers.sync(callPhoneNumber._id)
        setCanSync(true)
        if(!updated.success) return setErr(Array.isArray(updated.message) ? [...updated.message, 'If you have updated the telephone number make sure to save this phone number before trying to sync it'] : updated.message)
        onInputChange('sync_status', updated.data.sync_status)
        toast.success(`Number Synced Successfully`)
    }, [callPhoneNumber, onInputChange, canSync])

    const onSave = useCallback(async () => {

        setErr(false)
        let newState = JSON.parse(JSON.stringify(callPhoneNumber));

        if(!newState.number) {
            return setErr([`A number must be specified.`])
        }
        if(!newState.call_flow) {
            return setErr([`Routing must be selected.`])
        }
        if(!newState.name) {
            return setErr([`A name must be selected.`])
        }
        
        let saved;

        // toggle loaders and update/create the user
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.call_phone_numbers.update(newState._id, newState, true);
        } else {
            saved = await api.call_phone_numbers.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Phone Number Saved Successfully")
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [callPhoneNumber, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setCallPhoneNumber(baseState)
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={callPhoneNumber && callPhoneNumber._id ? "Update Phone Number" : "Create Phone Number"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!callPhoneNumber ? [] :  [
                (
                    <div>

                        <h2>Number Information</h2>

                        <FormGroup>
                            <label className="form-control-label">Name (Internal Description)</label>
                            <Input
                                type="text"
                                value={callPhoneNumber.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>      

                        <FormGroup>
                            <label className="form-control-label">Phone Number</label>
                            <PhoneInput
                                country={'us'}
                                value={callPhoneNumber.number || ''}
                                onChange={phone => onInputChange('number', phone)}
                            />
                        </FormGroup>      

                        <SearchCollections
                            addNull={true} 
                            collection="call_flows" 
                            title="Call Flow"
                            description="Select the routing that will be used for this number."
                            value={callPhoneNumber.call_flow}
                            onChange={(obj) => onInputChange('call_flow', obj.value)}
                            filter={{
                                division: selected_division._id
                            }}
                        /> 

                        <hr />

                        <ReactSelect
                            title="Record Inbound Calls"
                            formGroup={true}
                            onChange={(obj) => onInputChange('record_inbound', obj.value)}
                            options={[
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ]}
                            value={callPhoneNumber.record_inbound}
                        />    
                       
                        <ReactSelect
                            title="Record Outbound Calls"
                            formGroup={true}
                            onChange={(obj) => onInputChange('record_outbound', obj.value)}
                            options={[
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ]}
                            value={callPhoneNumber.record_outbound}
                        />    

                        <hr />

                        <SearchCollections
                            addNull={true} 
                            collection="lead_sources" 
                            title="Associated Lead Source"
                            description="If set, calls to this number will be associated with the source listed below."
                            value={callPhoneNumber.lead_source}
                            onChange={(obj) => onInputChange('lead_source', obj.value)}
                            filter={{
                                division: selected_division._id
                            }}
                        /> 

                        

                    </div>
                ),

                (
                    <div className='text-center my-4'>
                        {callPhoneNumber._id ? (
                            <Row>
                                <Col md={6} className='align-self-center'>
                                    <h2>Sync To Twilio</h2>
                                    <button 
                                        disabled={!canSync}
                                        onClick={onSync} 
                                        className='btn btn-info'
                                    >
                                        Run Twilio Sync <i className="fas fa-sync ml-2" />
                                    </button>
                                </Col>

                                <Col md={6} className='align-self-center'>
                                    <p className='mb-0'>CurrentStatus:</p>
                                    {callPhoneNumber.sync_status === 'full' ? (
                                        <div>
                                            <p className='text-s mb-0'><i className="fas fa-check text-success mr-2" />{' '}Synced </p>
                                            <p className='text-sm mb-0'>
                                            {callPhoneNumber.synced_at ? moment.unix(callPhoneNumber.synced_at).format('MMM Do, YYYY h:mm A') : moment().format('MMM Do, YYYY h:mm A')}
                                            </p>
                                        </div>
                                        ) : (
                                        <p className='text-s mb-0'><i className="fas fa-times text-danger mr-2" /> Not Synced</p>
                                    )}
                                </Col>
                            </Row>
                        ) : (
                            <p className='text-sm mb-0'><i className="fas fa-info-circle text-info mr-2" /> Creating a phone number will not update it's configuration on Twilio directly. You will have the option to do so by editing the number after you create it.</p>
                        )}
                        
                  
                       
                    </div>
                )
                      
            ]}
        />
    )

}




const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(CallPhoneNumberEdit);