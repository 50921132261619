const TableIcons = ({icons}) => (
    <div className="table-icons">
    {icons.map((icon, i) => (
        <span key={i} className="table-icon-wrapper">
            {icon.wrapper ? (
                <icon.wrapper {...icon}>
                    <span className={`table-icon table-icon-${icon.color}`}>
                        <i className={icon.icon} />
                    </span>
                </icon.wrapper>
            ) : (
                <span onClick={icon.onClick ? icon.onClick : () => {}} className={`table-icon table-icon-${icon.color}`}>
                    <i className={icon.icon} />
                </span>
            )}
        </span>        
    ))}
</div>
)

export default TableIcons